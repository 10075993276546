import faviconAlfa from "./aConsierge/images/favicon.png";
import faviconOpen from "./oConsierge/images/favicon.svg";
import faviconGpb from "./gpbConsierge/images/favicon.svg";
import faviconLifestyle from "./oLifestyle/images/favicon.png";
import React from "react";
import screenAlfa from './aConsierge/images/screen1@x2.png';
import screenOpen from './oConsierge/images/screen1@x3.png';
import screenGpb from './gpbConsierge/images/screen1@x3.png';
import screenLifestyle from './oLifestyle/images/screen1@x3.png';

export const AppDescriptions = {
    'a_concierge': {
        metaTags: [
            <title>А-Консьерж</title>,
            <meta name="description" content="А-Консьерж: скачивание приложения для Андроид"/>,
            <meta property="og:site_name" content="А-Консьерж"/>,
            <meta property="og:title" content = "А-Консьерж: скачивание приложения для Андроид" />,
            <meta property="og:type" content = "article" />,
            <meta property="og:image" content = "https://konsierge.com/images/Konsierge-SocialMedia-1200x630.png" />,
            <meta property="og:url" content="https://knsrg.ru/a_concierge" />,
            <link href={faviconAlfa} rel="shortcut icon" type="image/x-icon" />,
            <link rel="icon" href={faviconAlfa} />
        ],
        title: 'Будем знакомы, А-Консьерж',
        description: `Это личный помощник в вашем смартфоне, который помогает в решении любых вопросов 24/7.
                        <br/><br/>
                        Через А-Консьерж можно:
                        <br/><br/>
                        <ul>
                            <li>получать юридические консультации;</li>
                            <li>заказывать билеты;</li>
                            <li>брать в прокат автомобили или арендовать другой транспорт;</li>
                            <li>бронировать номера в отелях;</li>
                            <li>резервировать столики в ресторанах, барах или клубах;</li>
                            <li>доставлять подарки или цветы в любую точку мира;</li>
                            <li>получать медицинские консультации от практикующих врачей.</li>
                        </ul>
                        <br/><br/>`,
        screen: screenAlfa,
        appStoreLink: "https://apps.apple.com/ru/app/%D0%B0-%D0%BA%D0%BE%D0%BD%D1%81%D1%8C%D0%B5%D1%80%D0%B6/id1409566434",
        googlePlayLink: '',
        appGalleryLink: 'https://appgallery.huawei.com/#/app/C108761793',
        apkLink: 'https://link.konsierge.com/a_consierge',
        apkName: 'a_consierge.apk',
        askQuestions: `Наши контакты:
        <br/>
        <a href="mailto:alfa@knsrg.ru">alfa@knsrg.ru</a>`
    },
    'o_concierge': {
        metaTags: [
            <title>О.Консьерж</title>,
            <meta name="description" content="О.Консьерж: скачивание приложения для Андроид"/>,
            <meta property="og:site_name" content="О.Консьерж"/>,
            <meta property="og:title" content = "О.Консьерж: скачивание приложения для Андроид" />,
            <meta property="og:type" content = "article" />,
            <meta property="og:image" content = "https://konsierge.com/images/Konsierge-SocialMedia-1200x630.png" />,
            <meta property="og:url" content="https://knsrg.ru/o_concierge" />,
            <link href={faviconOpen} rel="shortcut icon" type="image/x-icon" />,
            <link rel="icon" href={faviconOpen} />
        ],
        title: 'О.Консьерж',
        description: `Мобильное приложение, с помощью которого вы можете общаться с профессиональной
                консьерж-службой, которая поможет вам в решении любых вопросов
                24 часа в сутки 365 дней в году.
                <br /><br />
                Вы бесплатно получите необходимую информацию и помощь в любой точке мира:
                <br /><br />
                <ul>
                    <li>юридические консультации по различным областям права</li>
                    <li>посещение бизнес-залов в аэропортах по QR-коду</li>
                    <li>бронирование билетов на любой вид транспорта, регистрация на рейсы авиакомпаний</li>
                    <li>аренда транспорта, прокат автомобилей и других средств передвижения</li>
                    <li>бронирование номеров в отелях и на мировых курортах</li>
                    <li>рекомендация и бронирование столов в ресторанах, барах и клубах</li>
                    <li>доставка подарков и цветов в любую точку мира</li>
                    <li>медицинские консультации от практикующих врачей</li>
                </ul>
                <br /><br />`,
        screen: screenOpen,
        appStoreLink: "https://apps.apple.com/ru/app/%D0%BE-%D0%BA%D0%BE%D0%BD%D1%81%D1%8C%D0%B5%D1%80%D0%B6/id1286643845",
        googlePlayLink: "https://play.google.com/store/apps/details?id=ru.konsierge.konsierge",
        appGalleryLink: 'https://appgallery.huawei.com/#/app/C108761427',
        apkLink: 'https://link.konsierge.com/o.consierge',
        apkName: '',
        askQuestions: `Остались вопросы? Звоните <a href="tel:+74957750566">+7 495 775 05 66</a>, <a href="tel:88007000566">8 800 700 05 66</a>, пишите <a href="mailto:open@knsrg.ru">open@knsrg.ru</a> мы на связи 😊`
    },
    'gpb_concierge': {
        metaTags: [
            <title>Премиум Консьерж</title>,
            <meta name="description" content="Премиум Консьерж: скачивание приложения для Андроид"/>,
            <meta property="og:site_name" content="Премиум Консьерж"/>,
            <meta property="og:title" content = "Премиум Консьерж: скачивание приложения для Андроид" />,
            <meta property="og:type" content = "article" />,
            <meta property="og:image" content = "https://konsierge.com/images/Konsierge-SocialMedia-1200x630.png" />,
            <meta property="og:url" content="https://knsrg.ru/gpb_concierge" />,
            <link href={faviconGpb} rel="shortcut icon" type="image/x-icon" />,
            <link rel="icon" href={faviconGpb} />
        ],
        title: 'Премиум Консьерж',
        description: `Мобильное приложение, с помощью которого вы можете общаться с профессиональной
                консьерж-службой, которая поможет вам в решении любых вопросов
                24 часа в сутки 365 дней в году.
                <br /><br />
                Вы бесплатно получите необходимую информацию и помощь в любой точке мира:
                <br /><br />
                <ul>
                    <li>юридические консультации по различным областям права</li>
                    <li>посещение бизнес-залов в аэропортах по QR-коду</li>
                    <li>бронирование билетов на любой вид транспорта, регистрация на рейсы авиакомпаний</li>
                    <li>аренда транспорта, прокат автомобилей и других средств передвижения</li>
                    <li>бронирование номеров в отелях и на мировых курортах</li>
                    <li>рекомендация и бронирование столов в ресторанах, барах и клубах</li>
                    <li>доставка подарков и цветов в любую точку мира</li>
                    <li>медицинские консультации от практикующих врачей</li>
                </ul>
                <br /><br />`,
        screen: screenGpb,
        appStoreLink: "https://apps.apple.com/ru/app/%D0%B3%D0%BF%D0%B1-%D0%BA%D0%BE%D0%BD%D1%81%D1%8C%D0%B5%D1%80%D0%B6/id1455820702",
        googlePlayLink: "https://play.google.com/store/apps/details?id=com.konsierge.gazprom",
        appGalleryLink: "https://appgallery.huawei.ru/app/C108726451",
        apkLink: 'https://link.konsierge.com/gpb-consierge',
        apkName: '',
        askQuestions: `Остались вопросы? Звоните <a href="tel:+74952298861">+7 495 229 88 61</a>, пишите <a href="mailto:gpb@knsrg.ru">gpb@knsrg.ru</a> мы на связи 😊`
    },
    'o_lifestyle': {
        metaTags: [
            <title>O.Lifestyle</title>,
            <meta name="description" content="O.Lifestyle: скачивание приложения для Андроид"/>,
            <meta property="og:site_name" content="O.Lifestyle"/>,
            <meta property="og:title" content = "O.Lifestyle: скачивание приложения для Андроид" />,
            <meta property="og:type" content = "article" />,
            <meta property="og:image" content = "https://konsierge.com/images/Konsierge-SocialMedia-1200x630.png" />,
            <meta property="og:url" content="https://knsrg.ru/o_lifestyle" />,
            <link href={faviconLifestyle} rel="shortcut icon" type="image/x-icon" />,
            <link rel="icon" href={faviconLifestyle} />
        ],
        title: 'O.Lifestyle',
        description: `Мобильное приложение, с помощью которого вы можете общаться с профессиональной
                консьерж-службой, которая поможет вам в решении любых вопросов
                24 часа в сутки 365 дней в году.
                <br /><br />
                Вы бесплатно получите необходимую информацию и помощь в любой точке мира:
                <br /><br />
                <ul>
                    <li>юридические консультации по различным областям права</li>
                    <li>посещение бизнес-залов в аэропортах по QR-коду</li>
                    <li>бронирование билетов на любой вид транспорта, регистрация на рейсы авиакомпаний</li>
                    <li>аренда транспорта, прокат автомобилей и других средств передвижения</li>
                    <li>бронирование номеров в отелях и на мировых курортах</li>
                    <li>рекомендация и бронирование столов в ресторанах, барах и клубах</li>
                    <li>доставка подарков и цветов в любую точку мира</li>
                    <li>медицинские консультации от практикующих врачей</li>
                </ul>
                <br /><br />`,
        screen: screenLifestyle,
        appStoreLink: "https://apps.apple.com/ru/app/o-lifestyle/id1466045522",
        googlePlayLink: '',
        appGalleryLink: 'https://appgallery.huawei.com/#/app/C108726567',
        apkLink: 'https://link.konsierge.com/o.lifestyle',
        apkName: 'o.lifestyle.apk',
        askQuestions: `Остались вопросы? Звоните <a href="tel:+74959098048">+7 495 909 80 48</a>,
 пишите <a href="mailto:pb.open@knsrg.ru">pb.open@knsrg.ru</a> мы на связи 😊`
    }
};
