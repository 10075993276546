import React from "react";
import appStoreIcon from '../../assets/images/appstore.svg';
import googlePlayIcon from '../../assets/images/googleplay.svg';
import appGalleryIcon from '../../assets/images/appgallery.svg';
import { Helmet } from "react-helmet";
import {AppDescriptions} from "./consts";
import './app_download_page.styles.scss';

const AppDownloadPage: React.FC<{
    name: 'a_concierge' | 'o_concierge' | 'gpb_concierge' | 'o_lifestyle'
}> = ({name}) => {
    return <div className={name}>
        <Helmet>
            <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
            <meta name="google-site-verification" content="5IklxOr5FpR1Vfmz7QtJntLkEcMNjJ3YG_KC6mfm9nk" />
            {AppDescriptions[name].metaTags.map((tag: any) => tag)}
            <script>
                {`(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){

                (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),

                m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)

            })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

                ga('create', 'UA-59168148-1', 'auto');

                ga('send', 'pageview');`}
            </script>
        </Helmet>
        <div id="bot_title">
            <div className="container">
                <div className="container_block first">
                    <div className="section_title">
                        {name === 'a_concierge' ? <h1>{AppDescriptions[name].title}</h1>
                            : <h1>Добро пожаловать в {AppDescriptions[name].title}</h1>}
                    </div>
                    <div
                        className="bot_content CirceLight"
                        dangerouslySetInnerHTML={{__html: AppDescriptions[name].description}}
                    />
                </div>
                <div className="container_block second">
                    <img
                        src={AppDescriptions[name].screen}
                        className="img-responsive animated-img"
                    />
                </div>
            </div>
        </div>
        <div id="apps_black">
            <div className="container">
                <div className="col-xs-12 text-center">
                    {AppDescriptions[name].appStoreLink && <a
                        className="appstore_btn"
                        href={AppDescriptions[name].appStoreLink}
                        target="_blank"
                    >
                        <img src={appStoreIcon}/>
                    </a>}
                    {AppDescriptions[name].googlePlayLink && <a href={AppDescriptions[name].googlePlayLink} target="_blank">
                        <img src={googlePlayIcon}/>
                    </a>}
                    {AppDescriptions[name].appGalleryLink && <a
                        className="appgallery_btn"
                        href={AppDescriptions[name].appGalleryLink}
                        target="_blank"
                    >
                        <img src={appGalleryIcon}/>
                    </a>}
                </div>
            </div>
        </div>
        <div id="bot__how-it-works">
            <div className="container">
                {name === 'a_concierge' ? <div className="section_title">
                        Нажмите <a href="https://link.konsierge.com/a_consierge">здесь</a> для скачивания приложения на андроид
                </div>
                : <div className="section_title">
                        Для скачивания приложения на Андроид{name === 'o_concierge' || name === 'gpb_concierge' ? ' прямо с сайта' : ''} нажмите <a href={AppDescriptions[name].apkLink}>здесь</a>
                </div>}
                {name === 'a_concierge' ? <div className="bot_content">
                        На ваш смартфон загрузится установочный файл APK. После этого вам может потребоваться перейти в раздел «Мои файлы» для установки.
                </div>
                : <div className="bot_content">
                        На ваш смартфон загрузится установочный файл APK. После скачивания вам может потребоваться перейти в
                        раздел «Мои файлы» для установки
                </div>}
                {name === 'a_concierge' ? <div className="section_title">
                        Откройте файл a_consierge.apk и установите приложение. Затем разрешите установку приложений из неизвестных источников.
                </div>
                : <div className="section_title">
                        Откройте файл {AppDescriptions[name].apkName ? `(он будет называться ${AppDescriptions[name].apkName}) ` : ''}на телефоне и установите приложение
                </div>}
                {name !== 'a_concierge' && <div className="bot_content">
                    Нужно разрешить установку приложений из неизвестных источников. Если не получается – звоните или пишите, наши контакты ниже.
                </div>}
                <button
                    id="app_download_button"
                    onClick={() => window.open(AppDescriptions[name].apkLink)}
                >
                    Скачать АPK для Андроид
                </button>
            </div>
        </div>
        <div className="container">
            <div className="ask_questions" dangerouslySetInnerHTML={{__html: AppDescriptions[name].askQuestions}}>
            </div>
        </div>
    </div>
};

export default AppDownloadPage;
